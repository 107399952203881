/* eslint-disable @typescript-eslint/no-explicit-any */

export const search = (elements: Array<any>, field: string, value: any) => {
  if (elements && elements.length) return elements.find((item: any) => item[field] === value) || {}
  return {}
}

export const order = (elements: Array<any>, field: string, desc?: boolean) => {
  if (elements && elements.length) return elements.sort((a: any, b: any) => (a[field] > b[field] ? (desc ? -1 : 1) : (desc ? 1 : -1)))
  return []
}

export const order_text = (elements: Array<any>, field: string) => {
  if (elements && elements.length) return elements.sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0))
  return []
}

export const equals = (element1: any, element2: any, strict?: boolean) => {
  element1 = ('' + (element1 || '')).trim()
  element2 = ('' + (element2 || '')).trim()
  if (!element1 || !element2) return false
  if (strict) return element1 === element2
  return element1.toLowerCase() === element2.toLowerCase()
}

export const duplicates = (elements: Array<any>, field: string) => {
  const duplicates = []
  const arr = order_text(elements.slice(), field)
  for (let i = 0; i < arr.length - 1; i++) {
    if (equals(arr[i + 1][field], arr[i][field])) duplicates.push(arr[i].email)
  }
  return duplicates
}

export const get_attribute = (object: any, attribute: string, _default?: any) => {
  if (attribute.indexOf('.')) {
    let new_object = { ...object }
    attribute.split('.').forEach(part => (new_object = (new_object[part] === undefined || new_object[part] === null) ? (_default || '') : new_object[part]))
    object[attribute] = new_object
  }
  return object[attribute]
}
